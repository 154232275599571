require('./bootstrap');

const csrf_token = document.getElementsByName('csrf-token')[0].content;

$(document).ready(function () {

    if($('#successModal').attr('data-status') === 'opened') {
        $('#successModal').modal('show');
    }

    if($('#exampleModal').attr('data-status') === 'opened') {
        $('#exampleModal').modal('show');
    }

    $('.resource-nav a').click(function (e) {

        e.preventDefault();

        let el  = e.toElement.hash.replace('#', '');
        let top = document.getElementById(el)
            .getBoundingClientRect().top;

        $('html').animate({ scrollTop: top - 150});

    });

    $('#callToListing').click(function () {
        $.post({
            url: '/contact/phone',
            data: {
                _token: $('meta[name="csrf-token"]').attr('content'),
                name: $(this).data('name'),
                id: $(this).data('id')
            },
            success: function success(response) {
                console.log(response);
            }
        });
    });

});

const listingContactForm = document.getElementById('listing_contact_form');

if(listingContactForm) {

    const listingContactFormPostUrl = listingContactForm.action;

    listingContactForm.addEventListener('submit', function (e) {

        e.preventDefault();
        e.target.querySelector('button').disabled = true;
        document.getElementById('contact_success').textContent = '';
        document.getElementById('contact_sending').textContent = 'Invio in corso...';

        const contact_name = document.getElementById('contact_name');
        const contact_email = document.getElementById('contact_email');
        const contact_message = document.getElementById('contact_message');
        const contact_privacy = document.getElementById('contact_privacy');

        contact_name.classList.remove('is-invalid');
        contact_email.classList.remove('is-invalid');
        contact_message.classList.remove('is-invalid');
        contact_privacy.parentNode.classList.remove('border', 'border-danger');

        const data = {
            _token: csrf_token,
            listing_id: document.getElementById('contact_listing_id').value,
            promo_id: document.getElementById('contact_promo_id').value,
            language: document.getElementById('contact_language').value,
            contact_name: contact_name.value,
            contact_email: contact_email.value,
            contact_message: contact_message.value,
            contact_privacy: contact_privacy.checked,
        };

        fetch('/contact/send', {
            method: 'POST',
            headers:  {
                'Content-Type': 'application/json',
                'X-Requested-With': 'XMLHttpRequest'
            },
            body: JSON.stringify(data)
        })
            .then(response => response.json())
            .then(data => {
                e.target.querySelector('button').disabled = false;
                document.getElementById('contact_sending').textContent = '';
                if(data.errors) {
                    if(data.errors.contact_name) {
                        contact_name.classList.add('is-invalid');
                    }
                    if(data.errors.contact_email) {
                        contact_email.classList.add('is-invalid');
                    }
                    if(data.errors.contact_message) {
                        contact_message.classList.add('is-invalid');
                    }
                    if(data.errors.contact_privacy) {
                        contact_privacy.parentNode.classList.add('border', 'border-danger');
                    }
                } else {
                    if(data === 'ok') {
                        document.getElementById('contact_success').textContent = 'Messaggio inviato con successo';
                        e.target.reset();
                    }
                }
            });

    });

}

const contactModal = document.getElementById('exampleModal');
if(contactModal) {
    contactModal.addEventListener('show.bs.modal', (e) => {
        listingContactForm.reset();

        document.getElementById('contact_success').textContent = '';

        const contact_name = document.getElementById('contact_name');
        const contact_email = document.getElementById('contact_email');
        const contact_message = document.getElementById('contact_message');
        const contact_privacy = document.getElementById('contact_privacy');

        contact_name.classList.remove('is-invalid');
        contact_email.classList.remove('is-invalid');
        contact_message.classList.remove('is-invalid');
        contact_privacy.parentNode.classList.remove('border', 'border-danger');
    });
}
